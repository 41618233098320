




import { Component, Vue } from 'vue-property-decorator'
import { inject } from 'inversify-props'

@Component({ components: {} })
export default class Home extends Vue {
  created() {
    console.log('created')
  }
}
